import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Helper function to get authentication token
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return { Authorization: `Bearer ${token}` };
};

const PriceUpdate = () => {
  const [activeListings, setActiveListings] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [variationProfiles, setVariationProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [listingInventories, setListingInventories] = useState({});
  
  // Sayfalama için durum değişkenleri
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 1,
    total_count: 0,
    limit: 100,
    has_next_page: false,
    has_previous_page: false
  });
  const [filterText, setFilterText] = useState('');

  // Fetch active listings from Etsy with pagination
  const fetchActiveListings = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://api.canvashelper.com/api/etsy/shop-listings?page=${page}&limit=${pagination.limit}`, {
        headers: getAuthHeader(),
      });
      
      setActiveListings(response.data.results || []);
      setPagination(response.data.pagination || pagination);
    } catch (error) {
      setMessage(`Ürünler yüklenirken hata oluştu: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch variation profiles from your database
  const fetchVariationProfiles = async () => {
    try {
      const response = await axios.get('https://api.canvashelper.com/api/variation_profiles', {
        headers: getAuthHeader(),
      });
      setVariationProfiles(response.data || []);
    } catch (error) {
      setMessage(`Error fetching variation profiles: ${error.message}`);
    }
  };

    // Sayfa değiştirme fonksiyonları
  const handleNextPage = () => {
    if (pagination.has_next_page) {
      fetchActiveListings(pagination.current_page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.has_previous_page) {
      fetchActiveListings(pagination.current_page - 1);
    }
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= pagination.total_pages) {
      fetchActiveListings(page);
    }
  };

  // Artık aktif ürünlerle birlikte varyasyonlar geldiği için bu fonksiyonu basitleştirebiliriz
  const prepareSelectedListingData = () => {
    // Seçili ürünlerin envanter bilgilerini kullanıma hazırla
    const inventories = {};
    
    for (const listingId of selectedListings) {
      const listing = activeListings.find(l => l.listing_id === listingId);
      
      if (listing && listing.has_variations && listing.variations) {
        inventories[listingId] = {
          products: listing.variations.map(v => ({
            property_values: v.property_values.map(pv => ({
              property_name: pv.property_name,
              values: pv.values
            })),
            offerings: [{
              price: v.price,
              quantity: v.quantity
            }]
          }))
        };
      }
    }
    
    setListingInventories(inventories);
  };

  // Handle profile selection
  const handleProfileSelect = (profileId) => {
    const profile = variationProfiles.find(p => p.id === parseInt(profileId));
    setSelectedProfile(profile);
  };

  // Arama terimine göre eşleşen varyasyonları bul
  const findMatchingVariations = () => {
    if (!searchTerm.trim() || !selectedProfile || activeListings.length === 0) {
      return;
    }

    // Arama terimine göre varyasyonları eşleştir
    const matchedListings = [];

    activeListings.forEach(listing => {
      // Sadece varyasyonları olan ürünleri kontrol et
      if (listing.has_variations && listing.variations && listing.variations.length > 0) {
        const hasMatch = listing.variations.some(variation => {
          // Varyasyon değerlerinde arama terimini kontrol et
          return variation.property_values && variation.property_values.some(prop => 
            prop.values && prop.values.some(value => 
              value.toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        });

        if (hasMatch) {
          matchedListings.push(listing.listing_id);
        }
      }
    });

    setSelectedListings(matchedListings);
  };

  // Fiyat ve envanter güncellemesi yap
  const updateVariations = async () => {
    if (!selectedProfile || selectedListings.length === 0) {
      setMessage('Lütfen bir profil ve güncellenecek ürünleri seçin');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const updateData = {
        profileId: selectedProfile.id,
        listingIds: selectedListings,
        searchTerm: searchTerm // Eşleştirme için arama terimini gönder
      };

      const response = await axios.post('https://api.canvashelper.com/api/update-variations', updateData, {
        headers: getAuthHeader(),
      });
      
      if (response.data.failed_updates && response.data.failed_updates.length > 0) {
        // Başarısız olan güncellemeler varsa detayları göster
        const failCount = response.data.failed_updates.length;
        setMessage(
          `${response.data.updated_count} ürün başarıyla güncellendi, ${failCount} ürün güncellenemedi. ` +
          `Hata detayları için konsola bakın.`
        );
        
        // Hata detaylarını konsola yazdır
        console.error('Güncellenemeyen ürünler:', response.data.failed_updates);
      } else {
        setMessage(`${response.data.updated_count} ürün başarıyla güncellendi!`);
      }
    } catch (error) {
      setMessage(`Güncelleme sırasında hata oluştu: ${error.message}`);
      console.error('Güncelleme hatası:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch listings and profiles on component mount
  useEffect(() => {
    fetchActiveListings(1); // İlk sayfayı yükle
    fetchVariationProfiles();
  }, []);

  // Ürünleri filtrele
  const filteredListings = filterText.trim() 
    ? activeListings.filter(listing => 
        listing.title.toLowerCase().includes(filterText.toLowerCase()))
    : activeListings;
    
  // Seçili ürünler değiştiğinde varyasyon bilgilerini hazırla
  useEffect(() => {
    if (selectedListings.length > 0 && activeListings.length > 0) {
      prepareSelectedListingData();
    }
  }, [selectedListings, activeListings.length]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Fiyat ve Envanter Güncelleme</h1>
      
      {message && (
        <div className={`p-4 mb-4 rounded ${message.includes('hata') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {message}
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Active Listings</h2>
          
          {isLoading ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center mb-4">
                <div>
                  <span className="text-sm font-medium">Bu sayfadaki ürünler:</span>
                </div>
                <div>
                  <button
                    className="px-3 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    onClick={() => setSelectedListings(activeListings.map(l => l.listing_id))}
                  >
                    Tümünü Seç
                  </button>
                  <button
                    className="px-3 py-1 ml-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
                    onClick={() => setSelectedListings([])}
                  >
                    Temizle
                  </button>
                </div>
              </div>
              
              <div className="h-64 overflow-y-auto border rounded">
                {filteredListings.map(listing => (
                  <div 
                    key={listing.listing_id}
                    className="p-2 hover:bg-gray-100 flex items-center"
                  >
                    <input
                      type="checkbox"
                      id={`listing-${listing.listing_id}`}
                      checked={selectedListings.includes(listing.listing_id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedListings([...selectedListings, listing.listing_id]);
                        } else {
                          setSelectedListings(selectedListings.filter(id => id !== listing.listing_id));
                        }
                      }}
                      className="mr-2"
                    />
                    <label htmlFor={`listing-${listing.listing_id}`} className="cursor-pointer flex-1">
                      <div className="flex items-center">
                        {listing.image_url && (
                          <img 
                            src={listing.image_url} 
                            alt={listing.title} 
                            className="w-12 h-12 object-cover mr-2 rounded"
                          />
                        )}
                        <span className="line-clamp-2">{listing.title}</span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              
              <div className="mt-4 flex justify-between items-center">
                <div>
                  <span className="text-sm text-gray-600">
                    Toplam {pagination.total_count} ürün | Sayfa {pagination.current_page} / {pagination.total_pages}
                  </span>
                </div>
                
                <div className="flex space-x-2">
                  <button
                    className="px-3 py-1 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 disabled:opacity-50"
                    onClick={handlePreviousPage}
                    disabled={!pagination.has_previous_page || isLoading}
                  >
                    Önceki
                  </button>
                  
                  {/* Sayfa numaraları */}
                  <div className="flex space-x-1">
                    {pagination.total_pages <= 7 ? (
                      // 7 veya daha az sayfa varsa hepsini göster
                      [...Array(pagination.total_pages)].map((_, i) => (
                        <button
                          key={i}
                          className={`px-3 py-1 rounded-lg ${
                            pagination.current_page === i + 1
                              ? 'bg-blue-600 text-white'
                              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                          }`}
                          onClick={() => handlePageChange(i + 1)}
                          disabled={isLoading}
                        >
                          {i + 1}
                        </button>
                      ))
                    ) : (
                      // 7'den fazla sayfa varsa ellipsis (...) ekleyin
                      <>
                        {/* İlk sayfa */}
                        <button
                          className={`px-3 py-1 rounded-lg ${
                            pagination.current_page === 1
                              ? 'bg-blue-600 text-white'
                              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                          }`}
                          onClick={() => handlePageChange(1)}
                          disabled={isLoading}
                        >
                          1
                        </button>
                        
                        {/* Ellipsis başta */}
                        {pagination.current_page > 4 && (
                          <span className="px-3 py-1">...</span>
                        )}
                        
                        {/* Ortadaki sayfalar */}
                        {[...Array(5)].map((_, i) => {
                          const pageNum = Math.max(
                            2,
                            Math.min(
                              pagination.current_page - 2 + i,
                              pagination.total_pages - 1
                            )
                          );
                          
                          // Sayfa numarası geçerli aralıkta değilse gösterme
                          if (
                            pageNum <= 1 ||
                            pageNum >= pagination.total_pages ||
                            (pagination.current_page <= 4 && i >= 5) ||
                            (pagination.current_page > pagination.total_pages - 4 && i < 1)
                          ) {
                            return null;
                          }
                          
                          return (
                            <button
                              key={pageNum}
                              className={`px-3 py-1 rounded-lg ${
                                pagination.current_page === pageNum
                                  ? 'bg-blue-600 text-white'
                                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                              }`}
                              onClick={() => handlePageChange(pageNum)}
                              disabled={isLoading}
                            >
                              {pageNum}
                            </button>
                          );
                        })}
                        
                        {/* Ellipsis sonda */}
                        {pagination.current_page < pagination.total_pages - 3 && (
                          <span className="px-3 py-1">...</span>
                        )}
                        
                        {/* Son sayfa */}
                        <button
                          className={`px-3 py-1 rounded-lg ${
                            pagination.current_page === pagination.total_pages
                              ? 'bg-blue-600 text-white'
                              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                          }`}
                          onClick={() => handlePageChange(pagination.total_pages)}
                          disabled={isLoading}
                        >
                          {pagination.total_pages}
                        </button>
                      </>
                    )}
                  </div>
                  
                  <button
                    className="px-3 py-1 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 disabled:opacity-50"
                    onClick={handleNextPage}
                    disabled={!pagination.has_next_page || isLoading}
                  >
                    Sonraki
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Variation Profile</h2>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Variation Profile
            </label>
            <select
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              onChange={(e) => handleProfileSelect(e.target.value)}
              value={selectedProfile?.id || ''}
            >
              <option value="">Select a profile</option>
              {variationProfiles.map(profile => (
                <option key={profile.id} value={profile.id}>
                  {profile.name}
                </option>
              ))}
            </select>
          </div>
          
          {selectedProfile && (
            <div className="mb-6">
              <h3 className="font-medium mb-2">Profil Detayları:</h3>
              <div className="bg-gray-50 p-3 rounded text-sm">
                <p><span className="font-semibold">İsim:</span> {selectedProfile.name}</p>
                {selectedProfile.description && (
                  <p><span className="font-semibold">Açıklama:</span> {selectedProfile.description}</p>
                )}
                
                {/* Profil varyasyonlarını göster */}
                {selectedProfile.dimension_groups && selectedProfile.dimension_groups.length > 0 && (
                  <div className="mt-2">
                    <p className="font-semibold">Varyasyonlar:</p>
                    <div className="mt-1 max-h-40 overflow-y-auto">
                      <table className="min-w-full text-xs">
                        <thead>
                          <tr className="border-b">
                            <th className="py-1 text-left">Boyut</th>
                            <th className="py-1 text-left">Çerçeve</th>
                            <th className="py-1 text-right">Fiyat</th>
                            <th className="py-1 text-right">Miktar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProfile.dimension_groups.map(group => 
                            group.variations.map((variation, index) => (
                              <tr key={`${group.dimension_id}-${index}`} className="border-b border-gray-100">
                                <td className="py-1">{variation.friendly_dimension_name}</td>
                                <td className="py-1">{variation.friendly_frame_name}</td>
                                <td className="py-1 text-right">{variation.price.toFixed(2)}</td>
                                <td className="py-1 text-right">{variation.quantity}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Find Matching Variations
            </label>
            <div className="flex">
              <input
                type="text"
                placeholder="Enter variation value to match..."
                className="flex-1 px-3 py-2 border rounded-l-lg focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-r-lg hover:bg-blue-700"
                onClick={findMatchingVariations}
                disabled={!selectedProfile || isLoading}
              >
                Find
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Selected Listings ({selectedListings.length})</h2>
          <button
            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50"
            onClick={updateVariations}
            disabled={selectedListings.length === 0 || !selectedProfile || isLoading}
          >
            Update Selected
          </button>
        </div>
        
        {selectedListings.length > 0 ? (
          <div className="bg-white p-4 rounded-lg shadow overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="border-b">
                  <th className="px-4 py-2 text-left">Image</th>
                  <th className="px-4 py-2 text-left">Title</th>
                  <th className="px-4 py-2 text-left">Variations</th>
                  <th className="px-4 py-2 text-left">Current Price</th>
                  <th className="px-4 py-2 text-left">New Price</th>
                </tr>
              </thead>
              <tbody>
                {selectedListings.map(listingId => {
                  const listing = activeListings.find(l => l.listing_id === listingId);
                  const inventory = listingInventories[listingId];
                  
                  return (
                    <tr key={listingId} className="border-b hover:bg-gray-50">
                      <td className="px-4 py-2">
                        {listing?.image_url && (
                          <img 
                            src={listing.image_url} 
                            alt={listing.title} 
                            className="w-12 h-12 object-cover rounded"
                          />
                        )}
                      </td>
                      <td className="px-4 py-2">{listing?.title}</td>
                      <td className="px-4 py-2">
                        {listing.has_variations ? (
                          <div className="text-sm">
                            <div className="font-medium">{listing.variations?.length || 0} varyasyon</div>
                            <div className="text-xs mt-1 text-gray-600 max-h-20 overflow-y-auto">
                              {listing.variations?.slice(0, 3).map((variation, idx) => (
                                <div key={idx} className="mb-1">
                                  {variation.property_values.map(prop => (
                                    <span key={prop.property_name}>
                                      {prop.values.join(', ')} 
                                    </span>
                                  )).join(' / ')}
                                </div>
                              ))}
                              {listing.variations?.length > 3 && 
                                <div className="text-blue-500">+ {listing.variations.length - 3} daha...</div>
                              }
                            </div>
                          </div>
                        ) : (
                          <span className="text-gray-500">Varyasyon yok</span>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {listing?.price?.amount / listing?.price?.divisor} {listing?.price?.currency_code}
                      </td>
                      <td className="px-4 py-2">
                        {selectedProfile ? (
                          <span className="text-green-600 font-medium">
                            Will be updated based on profile
                          </span>
                        ) : (
                          <span className="text-gray-500">No profile selected</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="bg-white p-8 rounded-lg shadow text-center text-gray-500">
            No listings selected. Please select listings to update.
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceUpdate;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalImage from 'react-modal-image';  // react-modal-image kullanımı
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 20;
    const [publishingProducts, setPublishingProducts] = useState({}); // Yayınlanan ürünlerin durumu

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://etsyhelper.onrender.com/api/ready-products', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            const products = response.data;
            if (Array.isArray(products)) {
                setProducts(products);
            } else {
                console.error("Error: Expected an array but got something else.", products);
                setProducts([]);
            }
        })
        .catch(error => {
            console.error("Error fetching products:", error);
            setProducts([]);
            toast.error("Failed to fetch products.");  // Hata bildirimi
        });
    }, []);

    const handlePublishProduct = (productId) => {
        const token = localStorage.getItem('token');
        const eventSource = new EventSource(`https://etsyhelper.onrender.com/api/publish-product-stream/${productId}?token=${token}`);

        // Yayınlama durumunu başlatıyoruz
        setPublishingProducts(prevState => ({
            ...prevState,
            [productId]: {
                lastMessage: '',
                progress: 0
            }
        }));

        eventSource.onmessage = (e) => {
            const message = e.data;

            setPublishingProducts(prevState => {
                let progress = prevState[productId].progress;

                // İlerleme yüzdesini mesajlara göre güncelliyoruz
                if (message.includes('Ürün ve mağaza bilgileri alındı')) {
                    progress = 20;
                } else if (message.includes('Ürün verileri hazırlanıyor')) {
                    progress = 40;
                } else if (message.includes('Ürün oluşturuldu')) {
                    progress = 60;
                } else if (message.includes('Resim yükleme tamamlandı')) {
                    progress = 80;
                } else if (message.includes('Ürün başarıyla yayınlandı')) {
                    progress = 100;
                } else if (message.includes('Hata oluştu')) {
                    progress = 0; // Hata durumunda ilerleme sıfırlanabilir
                }

                return {
                    ...prevState,
                    [productId]: {
                        lastMessage: message,
                        progress: progress
                    }
                };
            });

            // Eğer işlem tamamlandıysa, ürünü listeden kaldır
            if (message.includes('Ürün başarıyla yayınlandı')) {
                setTimeout(() => {
                    setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
                    setPublishingProducts(prevState => {
                        const newState = { ...prevState };
                        delete newState[productId];
                        return newState;
                    });
                }, 2000); // 2 saniye sonra ürünü listeden kaldır

                toast.success(`Product ${productId} published successfully!`);
                eventSource.close();
            }
        };

        eventSource.onerror = (e) => {
            console.error('SSE connection error:', e);
            toast.error(`Failed to publish product ${productId}.`);
            eventSource.close();
        };
    };

    const handlePublishSelected = () => {
        selectedProducts.forEach(productId => {
            handlePublishProduct(productId);
        });
        // Seçimi temizle
        setSelectedProducts([]);
    };

    const handleSelectProduct = (productId) => {
        setSelectedProducts(prevSelected => 
            prevSelected.includes(productId)
                ? prevSelected.filter(id => id !== productId)
                : [...prevSelected, productId]
        );
    };

    const handleSelectAll = () => {
        if (selectedProducts.length === paginatedProducts.length) {
            setSelectedProducts([]);  // Hepsini seçimi kaldır
        } else {
            setSelectedProducts(paginatedProducts.map(product => product.id)); // Hepsini seç
        }
    };

    // Pagination (sayfalama) işlemleri
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const paginatedProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(products.length / productsPerPage);

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">Ready to Publish Products</h1>
            {paginatedProducts.length > 0 ? (
                <>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedProducts.length === paginatedProducts.length}
                            className="mr-2"
                        />
                        <label>Select All</label>
                        {selectedProducts.length > 0 && (
                            <button
                                onClick={handlePublishSelected}
                                className="ml-auto bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                Publish Selected
                            </button>
                        )}
                    </div>
                    <ul id="product-list" className="space-y-4">
                        {paginatedProducts.map((product) => (
                            <li key={product.id} className="bg-white shadow rounded-lg overflow-hidden flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-4 p-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={selectedProducts.includes(product.id)}
                                        onChange={() => handleSelectProduct(product.id)}
                                        className="mr-4"
                                    />
                                    {/* ModalImage ile resim gösterimi */}
                                    <ModalImage
                                        small={product.base_image}
                                        large={product.base_image}
                                        alt=''
                                        className="w-32 h-32 object-cover rounded-md cursor-pointer"
                                    />
                                </div>
                                <div className="flex-grow mt-4 md:mt-0">
                                    <h3 className="text-lg font-semibold">{product.name}</h3>
                                    <p className="text-gray-600">Profile: {product.variation_profile_name}</p>
                                    <p className="text-gray-600">Section: {product.section_title}</p>
                                    {/* Yayınlama durumu */}
                                    {publishingProducts[product.id] && (
                                        <div className="mt-2">
                                            {/* Son mesaj */}
                                            <p>{publishingProducts[product.id].lastMessage}</p>
                                            {/* Progress bar */}
                                            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                                                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${publishingProducts[product.id].progress}%` }}></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <button
                                    onClick={() => handlePublishProduct(product.id)} // Tek tek ürün gönderme butonu
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-4 md:mt-0"
                                >
                                    Publish
                                </button>
                            </li>
                        ))}
                    </ul>

                    {/* Pagination */}
                    <div className="flex justify-center mt-4">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentPage(index + 1)}
                                className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <p>No products ready for publishing.</p>
            )}
            {/* Toast Container */}
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default ProductList;

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import DraftProducts from './pages/DraftProducts';
import MyAccount from './pages/MyAccount/MyAccount';
import ProductEdit from './pages/ProductEdit';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { AuthProvider, useAuth } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import StoreProtectedRoute from './components/StoreProtectedRoute'; // Yeni eklenen
import BulkUpload from './pages/BulkUpload';
import AccountDetails from './pages/MyAccount/AccountDetails';
import SupplierSettings from './pages/MyAccount/SupplierSettings';
import Subscription from './pages/MyAccount/Subscription';
import VariationProfiles from './pages/VariationProfiles';
import Stores from './pages/MyAccount/Stores';
import DescriptionGenerator from './pages/MyAccount/DescriptionGenerator';
import Success from './pages/Success';
import OAuthCallback from './pages/OAuthCallback';
import Support from './pages/Support';
import Studio from './pages/Studio';
import ActiveProductList from './pages/ActiveProductList';
import TourGuide from './components/TourGuide';
import PreparedListings from './pages/PreparedListings';
import Orders from './pages/Orders';
import Dashboard from './pages/Dashboard';
import PriceUpdate from './pages/PriceUpdate';
import OrderDetail from './pages/OrderDetail';
import AllProducts from './pages/AllProducts';
import { quantum } from 'ldrs';
import './App.css';

quantum.register();

function AppContent() {
  const { isAuthenticated, loading } = useAuth();
  const [notification, setNotification] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);



  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <l-quantum size="45" speed="1.75" color="black"></l-quantum>
      </div>
    );
  }

  return (
    isAuthenticated ? (
      <div className={`flex ${sidebarOpen ? 'ml-64' : ''}`}>
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
        <div className="flex-1 flex flex-col">
          <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
          <div className="container mx-auto">
            {notification && (
              <div className={`alert ${notification.includes('successful') ? 'alert-success' : 'alert-error'}`}>
                {notification}
              </div>
            )}
          </div>
          <div className="flex-1 bg-gray-50"> {/* Bu satır değişti */}
            <div className="container mx-auto py-8">
              <Routes>
                {/* Mağaza gerektirmeyen sayfalar */}
                <Route path="/my-account/stores" element={<PrivateRoute><Stores /></PrivateRoute>} />
                <Route path="/my-account/account-details" element={<PrivateRoute><AccountDetails /></PrivateRoute>} />
                <Route path="/my-account/subscription" element={<PrivateRoute><Subscription /></PrivateRoute>} />
                <Route path="/support" element={<Support />} />
                <Route path="/success" element={<Success />} />
                <Route path="/oauth/callback" element={<OAuthCallback />} />

                {/* Mağaza gerektiren sayfalar */}

                <Route path="/studio" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <Studio />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/all-products" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <AllProducts />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <Dashboard />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/orders" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <Orders />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                {/* Yeni eklenen Order Details route'u */}
                <Route path="/orders/:orderId" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <OrderDetail />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />
                
                <Route path="/draft-products" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <DraftProducts />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/pages/edit-product/:id" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <ProductEdit />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/variation-profiles" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <VariationProfiles />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/price-update" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <PriceUpdate />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/my-account/description-generator" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <DescriptionGenerator />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/bulk-upload" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <BulkUpload />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/prepared-listings" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <PreparedListings />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/active-products" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <ActiveProductList />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                <Route path="/my-account/supplier-settings" element={
                  <PrivateRoute>
                    <StoreProtectedRoute>
                      <SupplierSettings />
                    </StoreProtectedRoute>
                  </PrivateRoute>
                } />

                {/* Yönlendirme route'ları */}
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/register" element={<Navigate to="/" />} />
                <Route path="/forgot-password" element={<Navigate to="/" />} /> 
              </Routes>
            </div>
          </div>
          <TourGuide />
        </div>
      </div>
    ) : (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    )
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
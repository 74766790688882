import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaHome, FaCheck, FaHeadset, FaUser, FaFile, FaStore, FaSignOutAlt, FaFilter, FaImage } from 'react-icons/fa';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { FaArrowsRotate, FaBoxesStacked, FaListCheck, FaTruck, FaClipboardList } from 'react-icons/fa6';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const MySidebar = ({ sidebarOpen, toggleSidebar }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  const navigate = useNavigate();
  const { logout } = useAuth();

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://etsyhelper.onrender.com/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Kullanıcı verisi alınırken hata oluştu:', error);
      }
    };
    fetchUserData();
  }, []);

  const NavItem = ({ to, icon: Icon, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center px-3 py-2 rounded-xl transition-all duration-200 ${
          isActive 
            ? 'bg-purple-600 text-white shadow-lg shadow-purple-200' 
            : 'text-gray-600 hover:bg-purple-50'
        }`
      }
      onClick={toggleSidebar}
    >
      <Icon className="w-4 h-4 mr-2 transition-all duration-200" />
      <span className="text-xs font-medium">{children}</span>
    </NavLink>
  );

  return (
    <div 
      className={`fixed top-16 left-0 h-[calc(100vh-4rem)] w-64 bg-white shadow-2xl flex flex-col transform transition-transform duration-300 ease-in-out ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      {/* Logo - Fixed at top */}
      <div className="flex-shrink-0 p-4">
        <div className="flex items-center justify-center">
          <h1 className="text-xl font-bold bg-gradient-to-r from-purple-600 to-purple-400 bg-clip-text text-transparent">
            Canvas Helper
          </h1>
        </div>
      </div>

      {/* Main Navigation - Scrollable */}
      <nav className="flex-1 overflow-y-auto px-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="space-y-4">
          {/* Ana Sayfa */}
          <div>
            <NavItem to="/" icon={FaHome}>Dashboard</NavItem>
            <NavItem to="/orders" icon={FaClipboardList}>Orders</NavItem>
          </div>

          {/* Ürünler Bölümü */}
          <div className="space-y-2">
            <h2 className="px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider">
              Products
            </h2>
            <div className="space-y-1">
              <NavItem to="/active-products" icon={FaCheck}>Active Listings</NavItem>
              <NavItem to="/prepared-listings" icon={FaCheck}>Prepared Listings</NavItem>
              <NavItem to="/draft-products" icon={FaListCheck}>Draft Products</NavItem>
              <NavItem to="/bulk-upload" icon={FaBoxesStacked}>Bulk Upload</NavItem>
              <NavItem to="/price-update" icon={FaBoxesStacked}>Price Update</NavItem>
            </div>
          </div>

          {/* Destek */}
          <div>
            <NavItem to="/studio" icon={FaImage}>Mockup Creator</NavItem>
            <NavItem to="/support" icon={FaHeadset}>Support</NavItem>
          </div>

          {/* Hesap Ayarları */}
          <div className="space-y-2">
            <h2 className="px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider">
              Account Settings
            </h2>
            <div className="space-y-1">
              <NavItem to="/my-account/account-details" icon={FaUser}>Account Details</NavItem>
              <NavItem to="/my-account/subscription" icon={FaArrowsRotate}>Subscription Management</NavItem>
              <NavItem to="/variation-profiles" icon={FaFilter}>Variation Profiles</NavItem>
              <NavItem to="/my-account/description-generator" icon={FaFile}>Description Generator</NavItem>
              <NavItem to="/my-account/stores" icon={FaStore}>My Stores</NavItem>
              <NavItem to="/my-account/supplier-settings" icon={FaTruck}>Supplier Settings</NavItem>
            </div>
          </div>
        </div>
      </nav>

      {/* User Profile Section - Fixed at bottom */}
      <div className="flex-shrink-0 border-t border-gray-100 p-4">
        <div 
          className="px-3 py-2 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
          onClick={toggleUserMenu}
        >
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <h4 className="text-xs font-semibold text-gray-700">
                {`${userData.first_name} ${userData.last_name}`}
              </h4>
              <p className="text-[11px] text-gray-500 truncate">{userData.email}</p>
            </div>
            {isUserMenuOpen ? (
              <FiChevronUp className="w-4 h-4 text-gray-400" />
            ) : (
              <FiChevronDown className="w-4 h-4 text-gray-400" />
            )}
          </div>
        </div>

        {isUserMenuOpen && (
          <div className="mt-2 space-y-1">
            <NavLink
              to="/my-account/account-details"
              className="flex items-center px-3 py-2 text-xs text-gray-600 rounded-xl hover:bg-purple-50"
              onClick={toggleSidebar}
            >
              <FaUser className="w-3 h-3 mr-2" />
              Account Details
            </NavLink>
            <button
              onClick={handleLogout}
              className="w-full flex items-center px-3 py-2 text-xs text-gray-600 rounded-xl hover:bg-purple-50"
            >
              <FaSignOutAlt className="w-3 h-3 mr-2" />
              Logout
            </button>
          </div>
        )}

        <div className="mt-4 px-3 py-2">
          <p className="text-[10px] text-gray-400">Canvas Helper v0.9</p>
        </div>
      </div>
    </div>
  );
};

export default MySidebar;